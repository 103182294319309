import "./App.css";
import { MicroFrontEndContainer } from "./mf";

function App() {
  return (
    <div id="skeleton-wrapper">
      <MicroFrontEndContainer id="mf-soi-information-db" mfName="mf-soi-information-db" />
    </div>
  );
}

export default App;
