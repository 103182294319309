import { MFContainer } from "@mondaydotcomorg/trident-skeleton";
import { getMFSkeleton, initSkeleton } from "./skeleton";

initSkeleton();

type MicroFrontEndContainerProps = Omit<Parameters<typeof MFContainer>[0], "skeleton">;

export const MicroFrontEndContainer = (props: MicroFrontEndContainerProps) => {
  return <MFContainer skeleton={getMFSkeleton()} {...props} />;
};
