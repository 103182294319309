import { TridentSkeleton, HostEnvironment } from "@mondaydotcomorg/trident-skeleton";

export const SKELETON_KEY = Symbol.for("trident.skeleton");

export function initSkeleton() {
  if (window[SKELETON_KEY]) {
    return;
  }

  window[SKELETON_KEY] = new TridentSkeleton({
    api: {},
    // isDev: typeof __DEVELOPMENT__ !== "undefined",
    preloadedMFs: {},
    // allMfData: window.microfrontends.allMfData,
    mfUrl: "https://microfrontends.monday.com",
    host: { name: "INSERT_HERE", environment: HostEnvironment.development },
    // onEvent,
    isAssetsFlattened: true,
  });

  return window[SKELETON_KEY];
}

export function getMFSkeleton() {
  const result: TridentSkeleton = window[SKELETON_KEY];
  if (!result) {
    throw new Error("Skeleton not initialized");
  }
  return result;
}
